.spinner {
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
}

.spinner-centre {
  width: 60px;
  height: 60px;
  display: flex;
  margin: auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}