.input-group-navbar {
  .form-control,
  .btn {
    height: $input-height-lg;
    background: $navbar-input-bg;
    box-shadow: none;
    border: 0;
    padding: $input-padding-y-lg $spacer*0.75;

    &:focus {
      background: $navbar-input-bg;
      box-shadow: none;
      outline: 0;
    }
  }

  .btn {
    color: $gray-600;

    .feather {
      width: 20px;
      height: 20px;
    }
  }
}

.ReactPasswordStrength-strength-desc {
  font-size: 0.875rem;
  color: $gray-600;
  width: 85px;
}